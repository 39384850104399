import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Root from '../pages/root';
import Dashboard from '../pages/dashboard';
import { AuthenticatedLayout, Platforms } from 'carrier-fe';
import Test from "../pages/test";

function Router() {
	return (
		<BrowserRouter>
			<Routes>
				{/* TODO - REMOVE TEST PAGE */}
				<Route
					index
					path="/test"
					element={<Test />}
				/>

				<Route
					index
					path="/"
					element={<Root />}
				/>

				{/*
				 * TODO - FOLLOWING ROUTES NEED TO BE AUTH PROTECTED
				 *      - IS A TOKEN SET IN THE COOKIE?
				 *      - IS THAT TOKEN VALID (CHECK VIA THE SERVER?
				 *      - IF NOT -> LOGOUT AND REDIRECT TO LOGIN
				 */}
				<Route
					path="/"
					element={
						<AuthenticatedLayout
							platform={Platforms.REGISTRATION}
						/>
					}
				>
					<Route
						index
						path="/dashboard"
						element={<Dashboard />}
					/>
				</Route>

				{/* TODO - MAKE A PRETTY PAGE IN THE COMPONENT LIBRARY */}
				<Route
					path="*"
					element={<p>404</p>}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
